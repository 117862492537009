import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Share from '../../common/Share.vue'
import '../../../../assets/icon/font-icon.js'
import { Tag, Icon, Tabs, Tab, Image, Cell } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        Loading: Loading,
        LogVisit: LogVisit,
        Share: Share,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Cell.name]: Cell
    },
    data () {
        return {
            loadingShow: false,
            user: {},
            wallet: {},
            taskList: [],
            refereeList: [],
            supplier: {},
            page: { current: 0, size: 10, last: 0 },
            amount: 0,
            tabActive: 'run'
        }
    },
    mounted () {
        this.retrieveUser()
        this.retrieveUserSupplier()
        this.$refs.share.default('main')
        this.$refs.logVisit.createLog()
    },
    methods: {
        async retrieveUserSupplier () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/supply/supplier/retrieveUserSupplier', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.supplier = res.data
                window.sessionStorage.setItem(this.SESSION_MERCHANT, res.data.merchantCode)
                this.retrieveWallet()
                this.retrieveTaskPage()
            }
        },
        async retrieveTaskPage () {
            var pd = { supplierCode: this.supplier.code, current: this.page.current, size: this.page.size }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskRLS/retrieveTaskPage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.taskList = this.taskList.concat(res.data)
            }
        },
        async retrieveWallet () {
            var pd = { merchantCode: this.supplier.merchantCode }
            const { data: res } = await this.$http.post(this.UMS_URL + '/business/wallet/retrieveWallet', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        record () {
            this.$router.push({ path: '/mde/wallet/busRecord' })
        },
        recharge () {
            this.$router.push({ path: '/mde/hire/recharge', query: { type: 'MERCHANT', group: 'HIRE_SUPPLIER' } })
        },
        async retrieveUser () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { code: user }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveUser', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.user = res.data
            }
        },
        async updateState (code, state, task) {
            var pd = { taskCode: code, state: state }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskRLS/updateState', this.$qs.stringify(pd))
            if (res.status === '200') {
                task.state = state
                this.$dialog.alert({
                    title: '提示信息',
                    message: '更新招聘状态成功'
                })
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '更新招聘状态成功'
                })
            }
        },
        refresh (code) {
            this.$dialog.confirm({
                title: '提示信息',
                message: '是否消耗10个金豆刷新招聘为最新日期？'
            })
                .then(() => {
                    // on confirm
                    this.executeRefresh(code)
                })
        },
        async executeRefresh (code) {
            var merchant = window.sessionStorage.getItem(this.SESSION_MERCHANT)
            var pd = { taskCode: code, merchantCode: merchant }
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskRLS/refreshTask', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.page.current = 0
                this.taskList = []
                this.retrieveWallet()
                this.retrieveTaskPage()
            }
        },
        sign (code) {
            this.$router.push({ path: '/mde/hire/sign', query: { taskCode: code } })
        },
        seek (code) {
            this.$dialog.alert({
                title: '提示信息',
                message: '暂未开通此功能'
            })
        },
        create () {
            if (this.supplier.identify !== undefined && this.supplier.identity !== null) {
                this.$router.push({ path: '/mde/hire/publishCreate' })
            } else {
                this.$dialog.confirm({
                    title: '提示信息',
                    message: '您未开通商户，是否立即开通'
                })
                    .then(() => {
                        // on confirm
                        this.supplierCertify()
                    })
            }
        },
        update (code) {
            this.$router.push({ path: '/mde/hire/publishUpdate', query: { code: code } })
        },
        order () {
            this.$router.push({ path: '/mde/recruit/order' })
        },
        userCertify () {
            this.$router.push({ path: '/mde/hire/userCertify' })
        },
        supplierCertify () {
            this.$router.push({ path: '/mde/hire/supplierCertify' })
        },
        recmd () {
            this.$router.push({ path: '/mde/main/recmd' })
        },
        back () {
            this.$router.push({ path: '/mde/hire/home' })
        }
    }
}
