<template>
  <div class="page">
    <Navbar title="招聘发布" type="SMP" :callback="back" />
    <div class="simple">
      <van-row class="top">
        <van-col span="5"
          ><van-icon name="user-circle-o" size="46px" /><van-tag
            type="primary"
            :color="COLOR_M"
            ><van-icon name="edit" /> 上传</van-tag
          ></van-col
        >
        <van-col span="19">
          <van-row class="verify bdd">
            <van-col span="19">个人认证：{{ user.name }} </van-col>
            <van-col span="5"
              ><van-tag type="primary" :color="COLOR_M" @click="supplierCertify"
                ><van-icon name="edit" /> 修改</van-tag
              ></van-col
            >
          </van-row>
          <van-row class="verify">
            <van-col span="24" class="text-short"
              >商户认证：{{ supplier.name }}</van-col
            >
          </van-row>
        </van-col>
      </van-row>
      <van-row class="wallet">
        <van-col span="5"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-beans-s"></use></svg
        ></van-col>
        <van-col span="10" class="amount"
          >{{ wallet.beanBalance }}<span class="text">(金豆)</span></van-col
        >
        <van-col span="9" class="more"
          ><van-button
            plain
            :color="COLOR_S1"
            icon="balance-list-o"
            size="mini"
            @click="record"
            >明细</van-button
          ><van-button
            plain
            :color="COLOR_S2"
            icon="cash-back-record"
            size="mini"
            @click="recharge"
            >充值</van-button
          ></van-col
        >
      </van-row>
      <van-row class="btn">
        <van-col span="24"
          ><van-button
            :color="COLOR_M"
            icon="records"
            size="small"
            @click="create()"
            >发布招聘信息</van-button
          ></van-col
        >
      </van-row>
    </div>

    <!-- <div class="advice">
      <van-image
        class="img"
        :src="require('../../../../assets/images/train/adv-01.jpg')"
      ></van-image>
    </div> -->
    <div class="task">
      <van-row class="title">
        <van-col span="24" class="text"
          ><van-icon name="notes-o" /> 我的招聘</van-col
        >
      </van-row>
      <div class="task-list">
        <van-row
          class="task-item bdc-b1s-gray"
          v-for="task in taskList"
          :key="task.code"
          @click="handle(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="name text-short">{{
                task.name
              }}</van-col>
            </van-row>
            <van-row>
              <van-col span="24" class="label">
                <span
                  v-for="item in task.labels"
                  :key="item.name"
                  class="item bgc-gray"
                  >{{ item.name }}</span
                >
              </van-col>
            </van-row>
            <van-row>
              <van-col span="14" class="time"
                ><van-icon name="underway-o" />&nbsp;{{
                  task.publishTime
                }}</van-col
              >
              <van-col span="10" class="salary fc-cyan">
                <span v-if="task.salaryMin === 0 && task.salaryMax === 0"
                  >面议</span
                >
                <span v-if="task.salaryMin > 0">
                  <span v-if="task.salaryMin > 1000">
                    {{ (task.salaryMin / 1000).toFixed(1) }}K
                  </span>
                  <span v-else>{{ task.salaryMin }}元</span>
                </span>
                <span v-if="task.salaryMax > 0"
                  >-
                  <span v-if="task.salaryMax > 1000"
                    >{{ (task.salaryMax / 1000).toFixed(1) }}K</span
                  >
                  <span v-else>{{ task.salaryMax }}元</span></span
                >/<span v-if="task.salaryType === 'YER'">年</span>
                <span v-if="task.salaryType === 'MON'">月</span>
                <span v-if="task.salaryType === 'DAY'">天</span>
                <span v-if="task.salaryType === 'FQC'">次</span>
                <span v-if="task.salaryType === 'TIM'">小时</span>
                <span v-if="task.salaryType === 'MET'">米</span>
                <span v-if="task.salaryType === 'SMT'">平米</span></van-col
              >
            </van-row>
            <van-row class="count">
              <van-col span="12">浏览次数：{{ task.viewCount }}次</van-col>
              <van-col span="12" class="sign">报名人数：0人</van-col>
            </van-row>
            <van-row class="btn">
              <van-col span="24"
                ><van-button
                  :color="COLOR_S1"
                  icon="down"
                  size="mini"
                  v-if="task.state === 'PCS'"
                  @click="updateState(task.code, 'STP', task)"
                  >停止</van-button
                ><van-button
                  :color="COLOR_M"
                  icon="upgrade"
                  size="mini"
                  v-if="task.state === 'STP'"
                  @click="updateState(task.code, 'PCS', task)"
                  >开始</van-button
                >&nbsp;&nbsp;<van-button
                  :color="COLOR_M"
                  icon="edit"
                  size="mini"
                  @click="update(task.code)"
                  >修改</van-button
                >&nbsp;&nbsp;<van-button
                  :color="COLOR_M"
                  icon="replay"
                  size="mini"
                  @click="refresh(task.code)"
                  >刷新</van-button
                >&nbsp;&nbsp;<van-button
                  :color="COLOR_S1"
                  icon="sign"
                  size="mini"
                  @click="sign(task.code)"
                  >报名查看</van-button
                >&nbsp;&nbsp;<van-button
                  :color="COLOR_S2"
                  icon="aim"
                  size="mini"
                  @click="seek(task.code)"
                  >推荐工人</van-button
                ></van-col
              >
            </van-row>
          </van-col>
        </van-row>
      </div>
      <van-row v-if="taskList.length <= 0">
        <van-col span="24" class="no-record">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image>
        </van-col>
      </van-row>
    </div>
    <Loading :show="loadingShow" />
    <Tabbar active="publish" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="HRE"
      module-label="HRE_PUBLISH_INDEX"
    />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
